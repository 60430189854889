import React from "react"
import styled from "styled-components"

import { colors } from "utils/styles"
import useWindowSize from "hooks/useWindowSize"

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-bottom: 1em;
`
const StyledLink = styled.a`
  display: block;
  margin: 0 0.5em 0.5em 0;
  padding: 0.7em;
  font-size: 12px;
  text-align: center;
  color: ${colors.main};
  text-decoration: none;
  border: 1px solid ${colors.main};
`
export default function Social(props) {
  const getMobileOperatingSystem = () => {
    if (typeof navigator !== `undefined` && typeof window !== `undefined`) {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return "Windows Phone"
      }

      if (/android/i.test(userAgent)) {
        return "Android"
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS"
      }
    }

    return "unknown"
  }

  const windowSize = useWindowSize()
  return (
    <Wrapper>
      {props.links &&
        Object.keys(props.links).map(
          key =>
            props.links[key] && (
              <StyledLink
                key={key}
                href={
                  key !== "facebook" || props.links[key].includes("http")
                    ? props.links[key]
                    : windowSize.width > 1200
                    ? `https://facebook.com/${props.links[key]}`
                    : getMobileOperatingSystem() === "iOS"
                    ? `fb://profile/${props.links[key]}`
                    : `fb://page/${props.links[key]}`
                }
                rel="noopener noreferrer"
                target={windowSize.width > 1200 ? "_blank" : ""}
              >
                {key.charAt(0).toUpperCase() + key.slice(1)}
              </StyledLink>
            )
        )}
      {props.website && (
        <StyledLink
          href={props.website}
          target={windowSize.width > 1200 ? "_blank" : ""}
        >
          www.
        </StyledLink>
      )}
    </Wrapper>
  )
}
