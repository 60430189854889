import React, { useContext } from "react"
import styled from "styled-components"
import { graphql, Link } from "gatsby"

import { colors, mq } from "utils/styles"
import BackButton from "components/misc/BackButton"
import Menu from "components/Menu"
import SEO from "../components/seo/SEO"
import useWindowSize from "../hooks/useWindowSize"
import {
  getShortLocale,
  makeCurrentLocationObject,
} from "../utils/localization"
import I18nContext from "../utils/i18nContext"
import ResourceCategory from "../components/misc/ResourceCategory"

const Wrapper = styled.div`
  position: relative;
  z-index: 120;
  min-height: ${props =>
    props.windowHeight ? props.windowHeight + "px" : "100dvh"};
  background-color: ${colors.background};
`

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
`

const Title = styled.h1`
  margin: 0;
`

const Content = styled.div`
  padding: 1em 1em;

  ${mq.large} {
    width: 800px;
    margin: 0 auto;
  }
`

const RessourcesTitle = styled.h3`
  margin-bottom: 2rem;
`

export default function Page(props) {
  const { lang } = useContext(I18nContext)
  const page = props.data.page
  const ressources = props.data.ressources?.edges
    .map(edge => edge.node)
    .filter(node => getShortLocale(node.locale.id) === lang)
  const ressourcesCategories = props.data.ressourcesCategories?.edges
    .map(edge => edge.node)
    .filter(node => getShortLocale(node.locale.id) === lang)
  const locale = props.pageContext.locale
  const isPageRessource = props.pageContext.isPageRessource
  const windowSize = useWindowSize()
  const { translate } = useContext(I18nContext)
  const currentLocation = makeCurrentLocationObject(
    "page",
    props.pageContext.translations
  )

  const formattedRessources = ressources
    ? ressources.map(ressource => {
        ressource.cats = ressource.ressourceCategories?.nodes?.map(
          cat => cat.id
        )
        return ressource
      })
    : null

  const formatCat = cat => {
    cat.childCat = cat.wpChildren?.nodes?.map(formatCat)
    cat.ressources = formattedRessources.filter(ressource =>
      ressource.cats.includes(cat.id)
    )
    return cat
  }

  const formattedRessourcesCategories = ressourcesCategories
    ? ressourcesCategories.map(formatCat).filter(cat => !cat.parentDatabaseId)
    : null

  return (
    <>
      <SEO
        title={page.seo?.title ? page.seo.title : page.title}
        description={page.seo?.description ? page.seo.description : ""}
        slug={"pages/" + props.pageContext.slug}
        locale={locale}
      />
      <Wrapper windowHeight={windowSize.height}>
        {page && (
          <>
            <Header>
              <Link to="/">
                <BackButton />
              </Link>
              <Title>{page.title}</Title>
              <Menu locale={locale} currentLocation={currentLocation} />
            </Header>
            <Content>
              <article
                dangerouslySetInnerHTML={{
                  __html: page.content,
                }}
              />
            </Content>
          </>
        )}
        {isPageRessource && (
          <Content>
            <RessourcesTitle>
              {translate(
                "Découvrez les autres acteur·ice·s pros de la musique"
              )}
            </RessourcesTitle>
            {formattedRessourcesCategories.map(cat => (
              <ResourceCategory cat={cat} key={cat.id} />
            ))}
          </Content>
        )}
      </Wrapper>
    </>
  )
}

export const query = graphql`
  query ($slug: String!, $isPageRessource: Boolean!) {
    page: wpPage(slug: { eq: $slug }) {
      title
      content
      seo {
        canonicalUrl
        description
        openGraphDescription
        openGraphTitle
        openGraphType
        title
        twitterDescription
        twitterTitle
        socialImage {
          sourceUrl
        }
      }
    }
    ressources: allWpRessource @include(if: $isPageRessource) {
      edges {
        node {
          title
          content
          locale {
            id
          }
          translated {
            type: contentTypeName
            databaseId
            slug
            locale {
              id
            }
          }
          featuredImage {
            node {
              sourceUrl
            }
          }
          ressourceCategories {
            nodes {
              id: databaseId
              name
            }
          }
          acf: acfRessources {
            reseauxSociaux {
              facebook
              instagram
              soundcloud
              twitter
              youtube
              linkedin
            }
            siteWeb
          }
        }
      }
    }
    ressourcesCategories: allWpRessourceCategory(
      sort: { fields: acfCategoriesRessources___ordre }
    ) @include(if: $isPageRessource) {
      edges {
        node {
          name
          id: databaseId
          locale {
            id
          }
          parentDatabaseId
          wpChildren {
            nodes {
              name
              id: databaseId
              parentDatabaseId
              wpChildren {
                nodes {
                  name
                  id: databaseId
                  parentDatabaseId
                  wpChildren {
                    nodes {
                      name
                      id: databaseId
                      parentDatabaseId
                      wpChildren {
                        nodes {
                          name
                          id: databaseId
                          parentDatabaseId
                          wpChildren {
                            nodes {
                              name
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
