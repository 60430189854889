import React, { useState } from "react"
import Social from "../venue/Social"
import styled from "styled-components"
import { colors, mq } from "../../utils/styles"

const CategoryHeader = styled.div`
  cursor: pointer;
`
const CategoryTitle = styled.h4`
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  padding: 1rem;
  margin: -1rem;
`
const CategoryOpenIcon = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
  color: ${colors.main};
`

const CategoryWrapper = styled.div`
  margin-bottom: 1.5rem;
  padding: ${props => (props.isParent ? "0" : "1rem")};
  border: ${props => (props.isParent ? "none" : "1px solid " + colors.main)};
  ${props =>
    props.isParent &&
    `
    margin-bottom: ${props.isOpen ? "1rem" : "0"};
    
    & > .${CategoryHeader.styledComponentId} {
        margin-bottom: 1rem;
        display: inline-flex;
        border: 1px solid ${colors.main};
        transition: all 300ms ease-out 0s;
        
        & > .${CategoryTitle.styledComponentId} {
            margin: 0;
            padding: 0.4rem 0.8rem;
            background-color: ${props.isOpen ? colors.main : "transparent"};
            transition: all 300ms ease-out 0s;
            
            & > .${CategoryOpenIcon.styledComponentId} {
                color: ${props.isOpen ? colors.background : colors.main};
                transition: all 300ms ease-out 0s;
            }
        }
    }
  `};

  &:last-child {
    margin-bottom: 0;
  }
`

const RessourcesList = styled.div`
  margin-left: ${props => (props.isParent ? "1rem" : "0")};
`
const CategoryRessource = styled.div`
  display: block;
  padding: 1rem;
  margin-top: 1rem;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  & + & {
    padding-top: 2rem;
    border-top: 1px solid ${colors.main};
  }

  p {
    margin-bottom: 0;
  }
`
const CategoryRessourceHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  img {
    max-width: 250px;
  }

  h4 {
    margin-top: 0.25rem;
    margin-bottom: 1rem;
  }

  ${mq.medium} {
    flex-direction: row;
    align-items: flex-start;

    h4 {
      margin-bottom: 0;
    }
  }
`

export default function ResourceCategory({ cat }) {
  const [open, setOpen] = useState(false)

  return (
    <CategoryWrapper isParent={!!!cat.parentDatabaseId} isOpen={open}>
      <CategoryHeader onClick={() => setOpen(!open)}>
        <CategoryTitle>
          {open ? (
            <CategoryOpenIcon>-</CategoryOpenIcon>
          ) : (
            <CategoryOpenIcon>+</CategoryOpenIcon>
          )}
          <span>{cat.name}</span>
        </CategoryTitle>
      </CategoryHeader>

      {open && (
        <RessourcesList isParent={!!!cat.parentDatabaseId}>
          {cat.ressources &&
            cat.ressources.length > 0 &&
            cat.ressources.map((ressource, index) => (
              <CategoryRessource key={index}>
                <CategoryRessourceHeader>
                  {ressource.featuredImage?.node ? (
                    <img
                      src={ressource.featuredImage.node.sourceUrl}
                      style={{ marginBottom: "1rem", heigth: "50px" }}
                      alt={ressource.title}
                    />
                  ) : (
                    <h4>{ressource.title}</h4>
                  )}
                  <Social
                    links={ressource.acf.reseauxSociaux}
                    website={ressource.acf.siteWeb}
                  />
                </CategoryRessourceHeader>
                <div
                  dangerouslySetInnerHTML={{
                    __html: ressource.content,
                  }}
                />
              </CategoryRessource>
            ))}

          {cat.childCat &&
            cat.childCat.length > 0 &&
            cat.childCat.map(childCat => {
              console.log(childCat)

              return <ResourceCategory cat={childCat} key={childCat.id} />
            })}
        </RessourcesList>
      )}
    </CategoryWrapper>
  )
}
